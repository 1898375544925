import React from 'react'
import {  Link } from "gatsby";

import chelsea from "../img/chelsea.jpg";
import zach from "../img/zach.jpg";

const Author = ({author}) => {
  function getAuthorInfo(authorName) {
   switch (authorName) {
    case "Zach L.":
      return {
        firstName: "Zach",
        bio: " is an expert in personal finance, entrepreneurship, and investing dedicated to helping others obtain generational wealth. He has been featured in many popular publications including MSN, GOBankingRates, Apartment Therapy, Yahoo Finance, Credit Sesame, and many others.",
      }
    case "Chelsea L.":
      return {
        firstName: "Chelsea",
        bio: " has spent most of her life studying and implementing new ways to earn, save, and invest money. She is an entrepreneur at heart and loves to research new money topics and share that information with others.",
      }
    default:
      return null;
   }
  }

  const authorInfo = getAuthorInfo(author)

  return (
    authorInfo ?
      (<div className="author-box">
        <div className="author-box-center">
          <div className="author-left">
            <img src={author === "Zach L." ? zach : chelsea} className="author-image"/>
          </div>
          <div className="author-right">
            <div><Link to={'/about'}>{authorInfo.firstName}</Link>{`${authorInfo.bio} To learn more about ${authorInfo.firstName}, visit the `} <Link to={'/about'}>About Page</Link>.</div>
          </div>
        </div>
      </div>)
    : null
  )
}

export default Author
